import React from 'react';
import styles from './ContactSection3.module.css';
import { FaPhone, FaEnvelope, FaFacebookF, FaTwitter, FaInstagram, FaMapMarkerAlt } from 'react-icons/fa';

const ContactSection3 = () => {
    return (
        <div className={styles.container}>
            <div className={styles.location}>
                <h2>Our Location</h2>
                <p><FaMapMarkerAlt /> Shop no 59 & 60 mini central market DDA flat kalkaji New Delhi 110019</p>
                <a href="https://www.google.com/maps/dir/?api=1&destination=Shop+no+59+%26+60+mini+central+market+DDA+flat+kalkaji+New+Delhi+110019" target="_blank" rel="noopener noreferrer" className={styles.direction}>Get Direction</a>
            </div>
            <div className={styles.contact}>
                <h2>Contact Us</h2>
                <div className={styles.paragraph}>
                <p><FaPhone /> +91 9582600070</p>
                </div>
                <div className={styles.paragraph}>
                <p><FaPhone /> +91 9953107858</p>
                </div>
                <div className={styles.paragraph}>
                <p><FaEnvelope /> <a href="mailto:sparshmehta51@gmail.com">sparshmehta51@gmail.com</a></p>
                </div>
                
                <div className={styles.socialMedia}>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                </div>
            </div>
        </div>
    );
};

export default ContactSection3;
