// src/razorpayUtils.js

export const initiateRazorpayPayment = (orderDetails, onSuccess, onError) => {
  const { paymentOrderId, totalAmount, customerName, customerPhoneNumber, razorpayKeyId} = orderDetails;
  
    // Assuming paymentOrderId is valid when this function is called
    const options = {
      key: razorpayKeyId, // Replace with your Razorpay key
      amount: totalAmount * 100, // Amount in paise
      currency: "INR",
      name: "Cake Company",
      description: "Order Payment",
      order_id: paymentOrderId, // Pass the order_id
      handler: function (paymentResponse) {
        // On payment success
        onSuccess(paymentResponse);
      },
      modal: {
        ondismiss: function() {
          // On payment cancellation or failure
          onError('Payment cancelled by user');
        }
      },
      prefill: {
        name: customerName, 
        contact: customerPhoneNumber
      },
      theme: {
        color: "#3399cc"
      }
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  