// src/components/Footer.js
import React from 'react';
import styles from './Footer.module.css';

function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.fssaiContainer}>
                <p className={styles.fssaiCode}>FSSAI : XXXXXXXXXXXXXX</p>
            </div>
            <div className={styles.footerContent}>
                <div className={styles.footerSection}>
                    <h2>Contact Us</h2>
                    <p>Shop no 59 & 60 mini central market DDA flat kalkaji New Delhi 110019</p>
                    <p>+91 9582600070</p>
                    <p>+91 9953107858</p>
                    <p>sparshmehta51@gmail.com</p>
                </div>
                <div className={styles.footerSection}>
                    <h2>Stay Connected</h2>
                    <p>Follow us on social media channels</p>
                    <div className={styles.socialIcons}>
                        <i className="fab fa-facebook"></i>
                        <i className="fab fa-twitter"></i>
                        <i className="fab fa-instagram"></i>
                        <i className="fab fa-youtube"></i>
                    </div>
                </div>
                <div className={styles.footerSection}>
                    <h2>Timings</h2>
                    <p>Monday - Friday : 11:00 AM to 12:00 AM</p>
                    <p>Saturday : 11:00 AM to 12:00 AM</p>
                    <p>Sunday : 11:00 AM to 12:00 AM</p>
                </div>
            </div>
            <div className={styles.footerBottom}>
                <nav className={styles.footerNav}>
                    <a href="/">HOME</a>
                    <a href="/about">ABOUT</a>
                    <a href="/order-now">MENU</a>
                    <a href="/contact">CONTACT</a>
                    <a href="/order-now">ORDER NOW</a>
                    <a href="/terms-and-condition">TERMS & CONDITIONS</a>
                    <a href="/privacy-policy">PRIVACY POLICY</a>
                </nav>
                <p>Copyright &copy; {new Date().getFullYear()} Sparsh's Kitchen All Rights Reserved Designed By Sparsh Mehta.</p>
            </div>
        </footer>
    );
}

export default Footer;
