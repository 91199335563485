import React from 'react';
import styles from './Section6.module.css';
import foodImage from '../../Images/veg_indian_thali.jpg'; // Use the correct path to your image
import { useNavigate } from 'react-router-dom';

const Section6 = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/about');
    };

    return (
        <div className={styles.section}>
            <div className={styles.content}>
                <h2 className={styles.heading}>OUR STORY</h2>
                <p className={styles.subheading}>We operate our business under these guiding principles:</p>
                <p className={styles.text}>
                    Our idea from day one has been to create, produce and serve super great, full-flavored food that has a beginning, middle, and finish to every bite.
                    Everything at Heavens Kitchen is ruled by taste.
                </p>
                <p className={styles.text}>
                    We strive for efficient, uncompromised, sincere, fabulous, caring and exceptional service (as close to perfect as possible).
                </p>
                <p className={styles.text}>
                    We want to be a good place to eat for everyone. We offer something for everyone, from tempting Chinese to a delicious homemade meal to take home to the family.
                </p>
                <button className={styles.button} onClick={handleButtonClick}>More About Us</button>
            </div>
            <div className={styles.imageContainer}>
                <img src={foodImage} alt="Delicious food" className={styles.image} />
            </div>
        </div>
    );
};

export default Section6;
