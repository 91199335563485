import React, { useState, useEffect } from 'react';
import styles from './Section4.module.css';

const reviews = [
    {
        text: "I went there on last Sunday with my friend and it was awesome. Starting from snacks to main course in chicken everything was good. I loved the place and will suggest it to all.",
        author: "Sparsh Mehta"
    },
    {
        text: "Had a good experience. Ambience was great. Food was amazing. The drinks were also fine. Service was swift and courteous. Highly recommended for any occasion with friends, family or colleagues.",
        author: "Raghav Mehta"
    },
    {
        text: "Already had their main course like chicken changezi, chicken tikka masala, etc and they were good in taste but recently tried their tandoori chicken momo but seriously it was one of the best in the town. Juicy, fresh, tangy it was having all the flavours. Luv it…",
        author: "Anjali Kataria"
    },
    // Add more reviews as needed
];

const Section4 = () => {
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
        }, 5000); // Change review every 5 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    return (
        <div className={styles.section}>
            <div className={styles.reviewContainer}>
                <h2>CUSTOMER REVIEWS</h2>
                <p className={styles.reviewText}><i>“{reviews[currentReviewIndex].text}"</i></p>
                <p className={styles.reviewAuthor}>{reviews[currentReviewIndex].author}</p>
            </div>
            
        </div>
    );
};

export default Section4;
