import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ContactSection2.module.css';

const ContactSection2 = () => {
    const navigate = useNavigate();

    const handleOrderNowClick = () => {
        navigate('/order-now');
    };

    return (
        <div className={styles.container}>
            <div className={styles.map}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1763.6674083654532!2d77.2509039!3d28.5499245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3a6208a7b85%3A0xc3d1e3c9f28bb6b2!2sHeaven%E2%80%99s%20Kitchen!5e0!3m2!1sen!2sin!4v1638442096852!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
            <div className={styles.info}>
                <h2>We Are Open</h2>
                <p><strong>Anjali's Kitchen</strong></p>
                <p>Monday to Sunday</p>
                <p>11:00 AM - 12:00 AM</p>
                <button className={styles.orderButton} onClick={handleOrderNowClick}>Order Now</button>
            </div>
        </div>
    );
};

export default ContactSection2;
