import React from "react";
import styles from "./Section2.module.css";
import image1 from "../../Images/veg_singaporian_noodle.jpg";

const Section2 = () => {
  return (
    <section className={styles.section2}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.colMd6}>
            <div className={styles.heroImage}>
              <img src={image1} alt="Chicken Tikka Image" />
            </div>
          </div>
          <div className={styles.colMd6}>
            <div className={styles.heroContent}>
              <h1>Welcome To Anjali's kitchen</h1>
              <p>
                Founded by Ms.- Anjali Kataria in 2024, we were originally called Anjali Da
                Dhaba. After 1 years of hard work, we switched the name to Anjali's
                Kitchen in order to upgrade our ways to serve and fill your
                stomachs.
              </p>
              <div><a href="/about" className={styles.btn}>
                More About Us
              </a></div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;