import React, { useState, useEffect } from 'react';
import styles from './OTPVerification.module.css';
import axiosInstance from '../../api';
import { ClipLoader } from 'react-spinners'; // Import the loader
import { messaging } from '../../firebaseConfig';
import { getToken } from 'firebase/messaging';
import { FaEdit } from 'react-icons/fa'; // Import icon

const OTPVerification = ({ phoneNumber, handleBack, handleLoginSuccess }) => {
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [fcmToken, setFcmToken] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // New state for error message

    useEffect(() => {
        checkNotificationPermission();
    }, []);

    const checkNotificationPermission = async () => {
        try {
            const permissionStatus = await Notification.requestPermission();
            if (permissionStatus === 'granted') {
                const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY });
                setFcmToken(token);
            } else {
                console.log('Notification permission denied.');
            }
        } catch (err) {
            console.error('Error requesting notification permission:', err);
        }
    };


    const headers = {
        "Content-Type": "application/json",
        "DEVICE-TYPE": "Web",
        "VER": "1.0"
    };

    const requestData = {
        mobilenumber: phoneNumber,
        otp: otp, 
        client: process.env.REACT_APP_CLIENT
    };

    const getTokenAndSendToServer = async (userId) => {
        try {
            if (fcmToken) {
                const requestData = {
                    token: fcmToken,
                    userId: userId
                };
                const response = await axiosInstance.post(process.env.REACT_APP_SAVE_FCM_TOKEN, requestData, { headers });
                console.log('Token saved on server:', response.data);
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        } catch (err) {
            console.log('An error occurred while retrieving token. ', err);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage(''); // Clear previous error message

        // Validate OTP length
        if (otp.length !== 6) {
            setErrorMessage('Please enter a valid OTP!');
            setLoading(false);
            return;
        }

        try {
            const response = await axiosInstance.post(process.env.REACT_APP_USER_LOGIN, requestData, { headers });

            if (response.status === 200) {
                const data = response.data;
                localStorage.setItem('tokenId', data.responseObject.tokenString);
                localStorage.setItem('role', data.responseObject.role);
                localStorage.setItem('fullName', data.responseObject.fullName);

                await getTokenAndSendToServer(data.responseObject.id); // Ensure getId is correct

                handleLoginSuccess();
                window.location.reload();
            } else {
                console.error('Invalid OTP');
                setErrorMessage('Invalid OTP. Please try again.'); // Set error message
            }
        } catch (error) {
            console.error('Error:', error.response?.data?.responseMessage || 'An error occurred');
            setErrorMessage(error.response?.data?.responseMessage || 'An error occurred'); // Set error message
        } finally {
            setLoading(false);
        }
    };

    const handleOTPChange = (e) => {
        setOtp(e.target.value);
    };

    return (
        <div className={styles.overlay}>
            <div className={styles.loginBox}>
                <button className={styles.closeButton} onClick={handleBack}>X</button>
                <h2>Verify OTP</h2>
                <p>Enter the OTP sent to {phoneNumber}</p>
                <form onSubmit={handleSubmit}>
                    {/* <div className={styles.otpInputContainer}>
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                className={styles.otpInput}
                                value={digit}
                                onChange={e => handleChange(e.target, index)}
                                onFocus={e => e.target.select()}
                                onPaste={handlePaste}
                                onKeyDown={e => handleKeyDown(e, index)}
                                data-index={index}
                            />
                        ))}
                    </div> */}
                    <div>
                        <input
                            type="number"
                            value={otp}
                            onChange={handleOTPChange}
                            placeholder="Enter 6 digit OTP"
                            className={styles.otpInput}
                        />
                    </div>
                    {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>} {/* Display error message */}
                    <button type="submit" className={styles.submitButton} disabled={loading}>
                        {loading ? <ClipLoader size={20} color="#fff" /> : 'Verify'}
                    </button>
                </form>
                <button onClick={handleBack} className={styles.backButton}>
                    <FaEdit className={styles.icon} /> Edit Phone Number
                </button>
            </div>
        </div>
    );
};

export default OTPVerification;
