import React from 'react';
import styles from './Blog2.module.css';
import Header from '../Constant/Header/Header';
import Footer from '../Constant/Footer/Footer';

const Blog2 = () => {
    return (
        <div>
            <Header />

            <div className={styles.blog2Container}>
                <h1 className={styles.blog2Title}>Explore the Culinary Delights at Dhan Dhanya</h1>
                <p>
                    Nestled in the bustling neighborhood of West Patel Nagar, <strong>Dhan Dhanya</strong> is a culinary treasure that stands out among the many <a href="/order-now" className={styles.link}>restaurants in West Patel Nagar</a>. Known for its exquisite blend of traditional and contemporary Indian cuisine, this restaurant offers a diverse menu that caters to every palate. Whether you’re a local or just visiting, Dhan Dhanya is a must-visit destination for food lovers.
                </p>

                <h2 className={styles.blog2Subtitle}>A Warm Welcome Awaits You</h2>
                <p>
                    As soon as you step into Dhan Dhanya, you are greeted with warm hospitality and a cozy ambiance that sets the stage for a delightful dining experience. The restaurant's decor is a perfect blend of modern aesthetics and traditional Indian elements, making it a comfortable space for both casual diners and families.
                </p>

                <h2 className={styles.blog2Subtitle}>Start Your Meal with Delicious Soups</h2>
                <p>
                    Every great meal begins with a comforting soup, and Dhan Dhanya offers a variety of options that cater to all tastes. The <strong>Veg Lemon Coriander Soup</strong> is a refreshing choice, packed with zesty flavors that awaken your taste buds. If you're in the mood for something heartier, try the <strong>Chicken Sweet Corn Soup</strong>—a beloved classic that never disappoints.
                </p>
                <p>
                    For those who enjoy a bit of spice, the <strong>Chicken Hot & Sour Soup</strong> and <strong>Veg Hot & Sour Soup</strong> are perfect picks. These soups are not just appetizers; they're the ideal way to warm up on a chilly day or kick off your meal with vibrant flavors.
                </p>
                <div className={styles.imagePlaceholder}>[Insert Image of Soups]</div>

                <h2 className={styles.blog2Subtitle}>Sizzling Starters to Tempt Your Taste Buds</h2>
                <p>
                    The starters at Dhan Dhanya are a feast for the senses. Begin your culinary journey with our signature <strong>Stuffed Paneer Tikka</strong>—paneer stuffed with flavorful spices and grilled to perfection. Another must-try is the <strong>Tandoori Paneer Tikka</strong>, which brings the smoky flavors of the tandoor right to your table.
                </p>
                <p>
                    If you’re a fan of unique flavors, don’t miss out on the <strong>Achari Paneer Tikka</strong>, marinated in tangy pickles and grilled to perfection. For those craving something a bit more adventurous, the <strong>Mushroom Golouti</strong> is a delicious option that combines earthy flavors with a hint of spice.
                </p>
                <p>
                    Our non-vegetarian options are equally enticing. The <strong>Murgh Tandoori</strong> and <strong>Chicken Tikka</strong> are crowd favorites, marinated in a special blend of spices and grilled to perfection. The <strong>Murgh Afghani</strong>, with its creamy texture and rich flavor, is another dish that should not be missed.
                </p>
                <div className={styles.imagePlaceholder}>[Insert Image of Starters]</div>

                <h2 className={styles.blog2Subtitle}>Main Course Marvels: A Culinary Journey</h2>
                <p>
                    Dhan Dhanya’s main course is where the magic truly happens. Each dish is prepared with the freshest ingredients, ensuring maximum flavor and quality. For vegetarians, the <strong>Paneer Butter Masala</strong> is a rich and creamy delight that pairs perfectly with <strong>Tandoori Roti</strong> or <strong>Butter Naan</strong>.
                </p>
                <p>
                    Meat lovers will relish the <strong>Butter Chicken</strong>, a creamy and aromatic dish that has become synonymous with Indian cuisine. Another standout is the <strong>Chicken Tikka Masala</strong>, featuring tender pieces of chicken cooked in a spiced tomato gravy that is simply irresistible.
                </p>
                <p>
                    For those seeking a bit of adventure, the <strong>Mutton Rogan Josh</strong> and <strong>Murg Rara</strong> are rich in flavor and a true testament to the culinary heritage of India. Pair your main course with our <strong>Veg Biryani</strong> or <strong>Chicken Biryani</strong>—fragrant rice dishes layered with spices and meat that make for a wholesome meal.
                </p>
                <div className={styles.imagePlaceholder}>[Insert Image of Main Course]</div>

                <h2 className={styles.blog2Subtitle}>Delightful Desserts to End on a Sweet Note</h2>
                <p>
                    No meal is complete without dessert! At Dhan Dhanya, we offer a selection of traditional Indian sweets that will satisfy your sweet tooth. Our <strong>Gulab Jamun</strong> is soft, syrupy, and absolutely delicious. If you're in the mood for something light, the <strong>Ras Malai</strong> is a creamy, melt-in-your-mouth treat that is perfect after a rich meal.
                </p>
                <p>
                    For those looking for a more contemporary dessert, our <strong>Choice of Ice Cream</strong> offers a variety of flavors to choose from, making it a delightful ending to your culinary journey.
                </p>
                <div className={styles.imagePlaceholder}>[Insert Image of Desserts]</div>

                <h2 className={styles.blog2Subtitle}>The Perfect Place for Quick Bites</h2>
                <p>
                    Dhan Dhanya is not just about elaborate meals; it’s also the perfect spot for quick bites. If you’re in a hurry and searching for <a href="/order-now" className={styles.link}>quick bites in West Patel Nagar</a>, our menu has plenty of options to choose from. The <strong>Crispy Corn</strong> and <strong>Chilly Paneer Dry</strong> are quick, tasty, and sure to satisfy your cravings.
                </p>
                <p>
                    You can also explore our range of <strong>Veg Hakka Noodles</strong> and <strong>Chicken Hakka Noodles</strong> for a quick meal that is packed with flavors.
                </p>

                <h2 className={styles.blog2Subtitle}>Order Food Online: Convenience at Your Fingertips</h2>
                <p>
                    In today’s fast-paced world, convenience is key. Dhan Dhanya offers the option to <a href="/order-now" className={styles.link}>order food online</a>, allowing you to enjoy our delicious offerings from the comfort of your home. With a simple click, you can have your favorite dishes delivered right to your doorstep.
                </p>
                <p>
                    Our delivery service is prompt and efficient, ensuring that your food arrives fresh and ready to be enjoyed. Whether you're at home or the office, Dhan Dhanya makes it easy to indulge in our culinary delights.
                </p>

                <h2 className={styles.blog2Subtitle}>Celebrate Special Occasions with Dhan Dhanya</h2>
                <p>
                    Planning a special occasion? Dhan Dhanya is the perfect venue for events, gatherings, and celebrations. Our spacious dining area can accommodate large groups, making it ideal for birthday parties, anniversaries, and family gatherings.
                </p>
                <p>
                    We also offer customizable menus for your events, ensuring that every guest enjoys a memorable dining experience. Whether it’s a festive holiday celebration or a casual get-together, Dhan Dhanya can cater to your needs.
                </p>

                <h2 className={styles.blog2Subtitle}>Conclusion: Your Culinary Journey Awaits at Dhan Dhanya</h2>
                <p>
                    In the heart of West Patel Nagar, Dhan Dhanya stands out as a beacon of culinary excellence. With an extensive menu that caters to diverse tastes, exceptional service, and a warm atmosphere, it is a must-visit for anyone exploring the dining scene in West Delhi.
                </p>
                <p>
                    Whether you’re looking for a quick bite, a lavish meal, or a place to celebrate special occasions, Dhan Dhanya is the destination for you. Experience the best of Indian cuisine today!
                </p>
                <p>
                    Don’t forget to <a href="/order-now" className={styles.link}>click here to order online</a> and treat yourself to a delightful meal from Dhan Dhanya.
                </p>

                <footer className={styles.footer}>
                    <p>For more details on our menu and special offers, visit our website or follow us on social media.</p>
                </footer>
            </div>
            <Footer />
        </div>
    );
};

export default Blog2;
