import React from 'react';
import styles from './AboutSection2.module.css';

const AboutSection2 = () => {
    return (
        <div className={styles.aboutSection}>
            <div className={styles.heading}>
                Our Story </div>
            <div className={styles.paragraph}>
                Founded by Ms. Anjali Kataria in 2024, we were originally called Anjali Da Dhaba. After 1 years of hard work, we switched the name to Anjali Kitchen in order to upgrade our ways to serve and fill your stomachs.
            </div>
            <div className={styles.subheading}>We operate our business under these guiding principles:</div>
            <div className={styles.paragraph}>
                Our idea from day one has been to create, produce and serve super great, full-flavored food that has a beginning, middle, and finish to every bite. Everything at Anjali\
                 Kitchen is ruled by taste. We strive for efficient, uncompromised, sincere, fabulous, caring and exceptional service (as close to perfect as possible).
                We want to be a good place to eat for everyone. We offer something for everyone, from tempting Chinese to a delicious homemade meal to take home to the family.
            </div>
           
        </div>
    );
};

export default AboutSection2;
